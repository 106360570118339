import * as React from "react";
import { GetStartedForm } from "./GetStartedForm";
import { ContactInfo } from "./ContactInfo";

export const GetStarted = () => {
  return (
    <div className="w-full">
      <div className="container mx-auto mb-16">
        <div className="flex flex-col lg:flex-row">
          <GetStartedForm />
          <ContactInfo />
        </div>
      </div>
    </div>
  );
};
