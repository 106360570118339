import * as React from "react";
import { Layout } from "../components/global/Layout";
import { GetStarted } from "../components/contact/GetStarted";
import SEO from "../components/global/SEO";
import { graphql } from "gatsby";

const GetStartedPage = () => {
  return (
    <Layout>
      <SEO />
      <GetStarted />
    </Layout>
  );
};

export default GetStartedPage;
