import * as React from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as Yup from "yup";
import { SpinLoader } from "../../reusables/Mixed";
import { TextArea } from "./TextArea";
import { TextInput } from "./TextInput";
import { Alert } from "./Alert";
import { FormStatus, phoneRegExp } from "./constants";

const GetStartedFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Sorry, name you provided is too short")
    .max(50, "Sorry, name you provided is too long")
    .required("Please, tell us your name"),
  email: Yup.string()
    .email("Sorry, email format isn't right")
    .required("Please, provide us with your email address"),
  phone: Yup.string()
    .max(50, "Sorry, phone number you provided is too long")
    .matches(phoneRegExp, "Phone number is not valid"),
  contactMethod: Yup.string().required(
    "Please specify how you would like to be contacted"
  ),
  preferredTime: Yup.string(),
  company: Yup.string(),
  message: Yup.string().max(5000, "Sorry, your question is too long"),
});

export const GetStartedForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formStatus, setFormStatus] = React.useState(FormStatus.initial);

  const alertContainer = React.useRef(null);

  React.useEffect(() => {
    if (formStatus === FormStatus.success) {
      setTimeout(() => {
        setFormStatus(FormStatus.initial);
      }, 5000);
    }
  }, [formStatus]);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    contactMethod: "by-email",
    preferredTime: "",
    company: "",
    message: "",
  };

  const handleKeyUp = () => setFormStatus(FormStatus.initial);

  const handleSubmit = async (values, onSubmitProps) => {
    if (!executeRecaptcha) {
      console.log("Failed recaptcha check");
      setFormStatus(FormStatus.failed);
      return;
    }

    const { resetForm } = onSubmitProps;
    setFormStatus(FormStatus.sending);

    try {
      const response = await axios.post(
        "/.netlify/functions/get_started",
        values
      );
      if (response.status === 200 && response.data.status === "success") {
        setFormStatus(FormStatus.success);
        resetForm();
      } else {
        setFormStatus(FormStatus.failed);
      }
      alertContainer.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } catch (error) {
      console.log(error);
      setFormStatus(FormStatus.failed);
    }
  };

  return (
    <div className="m-6 lg:m-10 lg:basis-7/12">
      <div className="flex flex-col items-center justify-center mb-6 lg:justify-start lg:items-start">
        <span className="text-2xl font-bold mb-7">Let's get started</span>
        <span className="text-base font-medium text-center mb-5 lg:text-left">
          I’m really excited to work with you!
        </span>
        <span className="text-base text-center lg:text-left">
          Please, provide a few details about your contact info and a project we
          will be working on and we’ll reach back out to you shortly
        </span>
      </div>
      <div ref={alertContainer}>
        <Alert formStatus={formStatus} />
      </div>
      <div className="flex items-center justify-center ">
        <Formik
          initialValues={{ ...initialValues }}
          validationSchema={GetStartedFormSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => {
            const { errors, touched, isValid, dirty, values } = formikProps;
            /* console.log("formikProps = ", formikProps); */
            return (
              <Form className="w-full">
                <div className="space-y-10">
                  <TextInput
                    label="Your Name"
                    name="name"
                    onKeyUp={handleKeyUp}
                    required
                    {...formikProps}
                  />
                  <TextInput
                    label="Your Email Address"
                    name="email"
                    type="email"
                    onKeyUp={handleKeyUp}
                    required
                    {...formikProps}
                  />
                  <TextInput
                    label="Your Phone Number"
                    name="phone"
                    onKeyUp={handleKeyUp}
                    {...formikProps}
                  />

                  <div>
                    <div className="block text-base font-medium mb-6">
                      How would you like to be contacted?
                    </div>

                    <div className="space-y-4 flex flex-col">
                      <label>
                        <Field
                          name="contactMethod"
                          type="radio"
                          value="by-email"
                          onKeyUp={handleKeyUp}
                          checked={values.contactMethod === "by-email"}
                          className="mr-3"
                        />
                        By Email
                      </label>

                      <label>
                        <Field
                          name="contactMethod"
                          type="radio"
                          value="by-phone"
                          onKeyUp={handleKeyUp}
                          checked={values.contactMethod === "by-phone"}
                          className="mr-3"
                        />
                        By Phone
                      </label>
                    </div>

                    {errors.contactMethod && touched.contactMethod ? (
                      <div className="text-xs text-red-500">
                        {errors.contactMethod}
                      </div>
                    ) : null}
                  </div>

                  <TextArea
                    label="Any specific hours you prefer us to reach out?"
                    name="preferredTime"
                    onKeyUp={handleKeyUp}
                    placeholder="Anytime"
                    rows="2"
                    {...formikProps}
                  />
                  <TextInput
                    label="Your Company"
                    name="company"
                    onKeyUp={handleKeyUp}
                    {...formikProps}
                  />
                  <TextArea
                    label="A brief description"
                    name="message"
                    onKeyUp={handleKeyUp}
                    placeholder="Hi, I would like you to build me an awesome website to get more clients for me business..."
                    {...formikProps}
                  />
                </div>

                <button
                  type="submit"
                  disabled={!(isValid && dirty)}
                  className="btn btn-md mt-7 flex items-center disabled:bg-gray-300"
                >
                  {formStatus === FormStatus.sending && <SpinLoader />}
                  submit{formStatus === FormStatus.sending ? "ing" : ""} request
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
